import { SetupContext } from "@vue/composition-api";
import { AxiosInstance, AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import mime from "mime-types";

export default function useAttachment({ root }: Partial<SetupContext>) {
  const axiosInstance = root?.$store.getters[
    "api/getInstance"
  ] as AxiosInstance;

  const fileTypes = [
    "image/jpeg", // .jpeg / .jpg
    "image/png", // .png
    "application/pdf", // .pdf
    "image/webp", // .webp                                                                // .xml [if readable from casual users (RFC 3023, section 3)]
  ];

  const fileTypesString = fileTypes.join(",");

  const truncateFileName = (str: string) => {
    if (str.length < 22) return str;
    return `${str.slice(0, 10)}…${str.slice(-10)}`;
  };

  const humanReadableFileSize = (bytes: number, binary = false): string => {
    const base = binary ? 1024 : 1000;
    if (bytes < base) {
      return `${bytes} B`;
    }

    const prefix = binary ? ["Ki", "Mi", "Gi"] : ["k", "M", "G"];
    let unit = -1;
    while (Math.abs(bytes) >= base && unit < prefix.length - 1) {
      bytes /= base;
      ++unit;
    }
    return `${bytes.toFixed(1)} ${prefix[unit]}B`;
  };

  const downloadAttachment = (id: string, name?: string) => {
    axiosInstance
      .get(`attachment/${id}/download`, { responseType: "blob" })
      .then(({ data }) => {
        saveAs(data, name ? name : `${id}.${mime.extension(data.type)}`);
        const _window = window as any;
        _window.Arrow?.show(5000);
      });
  };

  const downloadAttachments = (
    attachments: { id: string; name?: string }[]
  ) => {
    for (const attachment of attachments)
      downloadAttachment(attachment.id, attachment.name);
  };

  const uploadImage = (image: File): Promise<AxiosResponse<any>> =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", image);
      axiosInstance
        .post("image/eventProgram", formData)
        .then(resolve)
        .catch(reject);
    });

  const deleteAttachment = (id: string): Promise<AxiosResponse<any>> =>
    new Promise((resolve, reject) => {
      axiosInstance
        .delete(`attachment/${id}`)
        .then(resolve)
        .catch(reject);
    });

  const getBase64Image = (id: string) =>
    axiosInstance.get(`image/${id}/data`).then((data) => {
      return data.data;
    });

  return {
    fileTypes,
    fileTypesString,
    truncateFileName,
    humanReadableFileSize,
    downloadAttachment,
    downloadAttachments,
    uploadImage,
    deleteAttachment,
    getBase64Image,
  };
}
